import {
    CartDispatcher as SourceCartDispatcher,
    CURRENT_WEBSITE
} from 'SourceStore/Cart/Cart.dispatcher';
import { getCartId } from 'Util/Cart';
// import EstimateShippingQuery from 'Query/EstimateShipping.query';
import CheckoutQuery from 'Query/Checkout.query';
import { fetchMutation } from 'Util/Request';
import { updateTotals, updateEstimateShipping } from 'Store/Cart/Cart.action';

export {
    CURRENT_WEBSITE
};

/** @namespace KaegiScandipwa/Store/Cart/Dispatcher */
export class CartDispatcher extends SourceCartDispatcher {
    async _updateCartData(cartData, dispatch) {
        dispatch(updateTotals(cartData));
        if (Object.keys(cartData).length > 0) {
            const guestCartId = getCartId();
            // const data = await fetchMutation(EstimateShippingQuery.getQuery(guestCartId));
            const {
                estimateShippingCosts
            } = await fetchMutation(CheckoutQuery.getEstimateShippingCosts({ country_id: 'CH' }, guestCartId));

            dispatch(updateEstimateShipping(estimateShippingCosts));
        }
    }
}

export default new CartDispatcher();
