/* eslint-disable */
import { isSignedIn, refreshAuthorizationToken } from 'Util/Auth';
import { refreshUid } from 'Util/Compare';
import {
    appendTokenToHeaders, getGraphqlEndpoint, parseResponse, postFetch
} from 'Util/Request/Request';

export const GRAPHQL_URI = '/graphql';
export const WINDOW_ID = 'WINDOW_ID';

const executePost = (args, callback, instance) => {
    const [queryObject, options] = args;
    const { query, variables } = queryObject;

    if (isSignedIn()) {
        refreshAuthorizationToken();
        refreshUid();
    }

    return parseResponse(postFetch(getGraphqlEndpoint(), query, variables, options));
};

export const postFetchProxy = (args, callback, instance) => {
    const [graphQlURI, query, variables, { headers = {} } = {}] = args;

    return fetch(graphQlURI,
        {
            method: 'POST',
            body: JSON.stringify({ query, variables }),
            headers: appendTokenToHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                ...headers
            })
        });
};

export default {
    'KaegiScandipwa/Util/Request/executePost': {
        function: executePost
    },
    'KaegiScandipwa/Util/Request/postFetch': {
        function: postFetchProxy
    }
};
