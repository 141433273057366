import {
    DETAILS_STEP,
    PAYMENT_TOTALS,
    SHIPPING_STEP
} from 'Route/Checkout/Checkout.config';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getCartId, setCartId } from 'Util/Cart';
import { fetchMutation, fetchQuery } from 'Util/Request';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import { RESTORE_CART_STEP, WALLEE_SCRIPT_ID } from '../config/Checkout.config';
import WalleePaymentQuery from '../query/WalleePayment.query';

/** @namespace Insign/ScandipwaWalleePayment/Helper/Checkout/Helper/paymentSuccessHandler */
export const paymentSuccessHandler = (args, originalConstructor, instance) => {
    const order_data = BrowserDatabase.getItem('wallee_payment_order_data');
    // remove order info from local storage
    BrowserDatabase.setItem({}, 'wallee_payment_order_data', ONE_MONTH_IN_SECONDS);
    const {
        order_id,
        billingAddress,
        isEmailAvailable,
        email
    } = order_data;

    // do similar things like in setDetailsStep function
    instance.state = {
        isLoading: false,
        isDeliveryOptionsLoading: false,
        requestsSent: 0,
        paymentMethods: [],
        shippingMethods: [],
        shippingAddress: {},
        selectedShippingMethod: '',
        isGuestEmailSaved: true,
        isCreateUser: false,
        estimateAddress: {},
        isPickInStoreMethodSelected: false,

        checkoutStep: DETAILS_STEP,
        paymentTotals: BrowserDatabase.getItem(PAYMENT_TOTALS) || {},
        email,
        billingAddress,
        orderID: order_id,
        wallee_payments_config: {},
        isEmailAvailable
    };
};

/** @namespace Insign/ScandipwaWalleePayment/Helper/Checkout/Helper/paymentFailureHandler */
export const paymentFailureHandler = async (args, originalConstructor, instance) => {
    defaultFailureHandler(args, originalConstructor, instance);
};

/** @namespace Insign/ScandipwaWalleePayment/Helper/Checkout/Helper/processFailureHandler */
export const processFailureHandler = async (args, originalConstructor, instance) => {
    defaultFailureHandler(args, originalConstructor, instance);
};

/** @namespace Insign/ScandipwaWalleePayment/Helper/Checkout/Helper/processRestoreCartHandler */
export const processRestoreCartHandler = async (args, originalConstructor, instance) => {
    defaultFailureHandler(args, originalConstructor, instance);
};

/** @namespace Insign/ScandipwaWalleePayment/Helper/Checkout/Helper/defaultFailureHandler */
export const defaultFailureHandler = async (args, originalConstructor, instance) => {
    const [props] = args;
    const { showErrorNotification } = props;

    const order_data = BrowserDatabase.getItem('wallee_payment_order_data');
    // remove order info from local storage
    BrowserDatabase.setItem({}, 'wallee_payment_order_data', ONE_MONTH_IN_SECONDS);
    const {
        order_id,
        billingAddress,
        shippingAddress,
        email
    } = order_data;

    showErrorNotification(__('Payment process was canceled!'));
    instance.state = {
        isLoading: false,
        isDeliveryOptionsLoading: false,
        requestsSent: 0,
        paymentMethods: [],
        shippingMethods: [],
        shippingAddress,
        selectedShippingMethod: '',
        isGuestEmailSaved: false,
        isCreateUser: false,
        estimateAddress: {},
        isPickInStoreMethodSelected: false,
        checkoutStep: RESTORE_CART_STEP,
        paymentTotals: BrowserDatabase.getItem(PAYMENT_TOTALS) || {},
        email,
        billingAddress,
        orderID: order_id,
        wallee_payments_config: {}
    };
    await restoreCart(props);
    await props.resetCart();

    BrowserDatabase.deleteItem(PAYMENT_TOTALS);

    // eslint-disable-next-line react/no-did-update-set-state
    instance.setState({
        checkoutStep: SHIPPING_STEP
    });
    getWalleeConfig(instance);
};

/** @namespace Insign/ScandipwaWalleePayment/Helper/Checkout/Helper/restoreCart */
export const restoreCart = async (props) => {
    const guest_cart_id = !isSignedIn() ? getCartId() : props.totals.id;
    const { restoreCart: { cart_id } } = await fetchMutation(WalleePaymentQuery.getRestoreCartMutation(guest_cart_id));
    setCartId(cart_id);
};

/** @namespace Insign/ScandipwaWalleePayment/Helper/Checkout/Helper/getWalleeConfig */
export const getWalleeConfig = async (instance) => {
    const guest_cart_id = !isSignedIn() ? getCartId() : instance.props.totals.id;
    const walleeConfig = await fetchQuery(WalleePaymentQuery.getQuery(guest_cart_id));
    const {
        getWalleePaymentConfig: {
            wallee,
            payment
        }
    } = walleeConfig;

    console.log(walleeConfig);

    instance.setState({
        wallee_payments_config: {
            wallee,
            payment
        }
    });

    // Render Script
    const script = document.getElementById(WALLEE_SCRIPT_ID);
    if (script) {
        // remove old script
        script.parentNode.removeChild(script);
    }
    const walleeScript = document.createElement('script');
    walleeScript.setAttribute('id', WALLEE_SCRIPT_ID);
    walleeScript.setAttribute('src', wallee.integrationMethod === 'lightbox' ? wallee.lightboxUrl : wallee.javascriptUrl);
    walleeScript.async = true;
    document.head.appendChild(walleeScript);
};

/** @namespace Insign/ScandipwaWalleePayment/Helper/Checkout/Helper/fallbackToPaymentPage */
export const fallbackToPaymentPage = async (instance, paymentMethod) => {
    if (instance.state.wallee_payments_config?.wallee?.paymentPageUrl) {
        window.location.replace(`${instance.state.wallee_payments_config.wallee.paymentPageUrl }&paymentMethodConfigurationId=${ paymentMethod.configurationId}`);
    } else {
        instance.props.showErrorNotification(__('Payment process could not be started!'));
        await restoreCart(instance.props);
        await instance.props.resetCart();
        BrowserDatabase.setItem('default', 'payment_provider', ONE_MONTH_IN_SECONDS);
    }
};
