/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getPaymentData } from '../data/purchase';
import { GTM_EVENT_KEY_ADD_PAYMENT_INFO } from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

/** @namespace Scandiweb/Gtm/Event/General/fireAddPaymentInfo */
export const fireAddPaymentInfo = debounceCallback(
    async (code) => {
        const paymentData = await getPaymentData(code);
        pushToDataLayer({
            event: GTM_EVENT_KEY_ADD_PAYMENT_INFO,
            ...paymentData,
        });
    }
);
