const updateInitialCartData = async (args, callback, instance) => {
    if (location.pathname.indexOf('checkout/onepage/success') === -1
        && location.pathname.indexOf('checkout/restoreCart') === -1) {
        return callback.call(instance, ...args);
    }

    return null;
};

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            updateInitialCartData
        }
    }
};
