/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

const addGtmSpecificFields = (args, callback) => [
    ...callback(...args),
    new Field('userExistingCustomer'),
    new Field('userLifetimeValue'),
    new Field('userLifetimeOrders'),
];

export default {
    'Query/MyAccount/Query': {
        'member-function': {
            _getCustomerFields: addGtmSpecificFields,
        },
    },
};
