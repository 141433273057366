/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

const addGtmSpecificFields = (args, callback) => ([
    ...callback(...args),
    new Field('store_name'),
    new Field('store_code'),
    new Field('locale'),
]);

export default {
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields: addGtmSpecificFields,
        },
    },
};
