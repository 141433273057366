/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getCheckoutEventData, getCheckoutOptionEventData } from '../data/checkout';
import {
    getCustomerData,
} from '../data/customer';
import { getPurchaseEventData } from '../data/purchase';
import {
    GTM_EVENT_KEY_CHECKOUT,
    GTM_EVENT_KEY_CHECKOUT_OPTION,
    GTM_EVENT_KEY_PURCHASE,
} from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

export const fireCheckoutOptionEvent = debounceCallback(async (step, option) => {
    const { customerId } = await getCustomerData();
    const optionData = await getCheckoutOptionEventData(step, option);
    pushToDataLayer({
        event: GTM_EVENT_KEY_CHECKOUT_OPTION,
        customerId,
        ...optionData,
    });
});

export const fireCheckoutEvent = debounceCallback(async (step) => {
    const checkoutData = await getCheckoutEventData(step);
    pushToDataLayer({
        event: GTM_EVENT_KEY_CHECKOUT,
        ...checkoutData,
    });
});

export const firePurchaseEvent = debounceCallback(async (orderId, guestQuoteId, cartItems) => {
    const purchaseData = await getPurchaseEventData(orderId, guestQuoteId, cartItems);
    pushToDataLayer({
        event: GTM_EVENT_KEY_PURCHASE,
        ...purchaseData,
    });
});
