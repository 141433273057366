import {
    getInitialState as sourceGetInitialState,
    CartReducer as SourceCartReducer,
    CART_TOTALS,
    updateCartTotals as sourceUpdateCartTotals,
    updateShippingPrice
} from 'SourceStore/Cart/Cart.reducer';
import { UPDATE_ESTIMATE_SHIPPING, UPDATE_TOTALS } from 'Store/Cart/Cart.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import PRODUCT_TYPE from 'Component/Product/Product.config';

export {
    CART_TOTALS,
    updateShippingPrice
};

/** @namespace KaegiScandipwa/Store/Cart/Reducer/updateCartTotals */
export const updateCartTotals = (action) => {
    const originalData = sourceUpdateCartTotals(action);
    const { cartData: { items = [] } = {} } = action;

    const cartSavings = items.reduce((savings, item) => {
        const {
            sku,
            quantity,
            product: {
                type_id,
                variants,
                price_range
            } = {}
        } = item;

        if (type_id === PRODUCT_TYPE.configurable) {
            const variant = variants.find((avar) => avar.product.sku === sku);
            const {
                product: {
                    price_range: {
                        maximum_price
                    }
                }
            } = variant;

            return (maximum_price.discount.amount_off * quantity) + savings;
        }

        return (price_range.maximum_price.discount.amount_off * quantity) + savings;
    }, 0);

    const cartTotals = {
        ...originalData.cartTotals,
        hasSpecialPrices: cartSavings > 0,
        cartSavings
    };

    BrowserDatabase.setItem(
        cartTotals,
        CART_TOTALS
    );

    return { cartTotals, isLoading: false };
};

/** @namespace KaegiScandipwa/Store/Cart/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    estimateShipping: null
});

/** @namespace KaegiScandipwa/Store/Cart/Reducer/CartReducer */
export const CartReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATE_TOTALS:
        /* eslint-disable-next-line new-cap */
        SourceCartReducer(state, action);
        return updateCartTotals(action, state);
    case UPDATE_ESTIMATE_SHIPPING:
        return {
            ...state,
            estimateShipping: action.estimateShipping
        };
    default:
        /* eslint-disable-next-line new-cap */
        return SourceCartReducer(state, action);
    }
};

export default CartReducer;
