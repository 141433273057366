import Loader from 'Component/Loader';
import {
    DETAILS_STEP
} from 'Route/Checkout/Checkout.config';

import { RESTORE_CART_STEP } from '../config/Checkout.config';

function renderRestoreCartStep() {
    return <Loader isLoading />;
}

const stepMap = (member, instance) => ({
    ...member,
    [RESTORE_CART_STEP]: {
        title: __('Loading'),
        url: '/checkout/restoreCart',
        render: renderRestoreCartStep.bind(instance),
        areTotalsVisible: false
    }
});

const renderGuestForm = (args, callback, instance) => {
    const {
        checkoutStep
    } = instance.props;

    if (checkoutStep === DETAILS_STEP || checkoutStep === RESTORE_CART_STEP) {
        return null;
    }

    return callback(...args);
};

export default {
    'Route/Checkout/Component': {
        'member-property': {
            stepMap
        },
        'member-function': {
            renderGuestForm
        }
    }
};
