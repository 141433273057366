import {
    UPDATE_TOTALS,
    ADD_PRODUCT_TO_CART,
    REMOVE_PRODUCT_FROM_CART,
    APPLY_COUPON_TO_CART,
    REMOVE_COUPON_FROM_CART,
    UPDATE_SHIPPING_PRICE,
    UPDATE_IS_LOADING_CART,
    addProductToCart,
    removeProductFromCart,
    updateTotals,
    updateShippingPrice,
    applyCouponToCart,
    removeCouponFromCart,
    updateIsLoadingCart
} from 'SourceStore/Cart/Cart.action';

export {
    UPDATE_TOTALS,
    ADD_PRODUCT_TO_CART,
    REMOVE_PRODUCT_FROM_CART,
    APPLY_COUPON_TO_CART,
    REMOVE_COUPON_FROM_CART,
    UPDATE_SHIPPING_PRICE,
    UPDATE_IS_LOADING_CART,
    addProductToCart,
    removeProductFromCart,
    updateTotals,
    updateShippingPrice,
    applyCouponToCart,
    removeCouponFromCart,
    updateIsLoadingCart
};

export const UPDATE_ESTIMATE_SHIPPING = 'UPDATE_ESTIMATE_SHIPPING';

/** @namespace KaegiScandipwa/Store/Cart/Action/updateEstimateShipping */
export const updateEstimateShipping = (estimateShipping) => ({
    type: UPDATE_ESTIMATE_SHIPPING,
    estimateShipping
});
