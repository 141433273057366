import Html from '@scandipwa/scandipwa/src/component/Html';
import {
    Notification as SourceNotification
} from 'SourceComponent/Notification/Notification.component';

import './Notification.override.style';

/**
 * Notification block
 * @class Notification
 * @namespace KaegiScandipwa/Component/Notification/Component
 */
export class NotificationComponent extends SourceNotification {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        super.componentDidMount();
    }

    render() {
        const { notification, id } = this.props;
        const { isNotificationVisible } = this.state;
        const { msgText, msgType } = notification;

        const mods = {
            type: msgType.toLowerCase(),
            is: isNotificationVisible ? 'opening' : 'closing'
        };

        /* Check msgText for String to use with Html Tag ->
        Tag is not working with object or something like that (Translated msgTexts are still objects) and will not work with the Html Tag.
        */
        const message = typeof msgText === 'string' ? <Html content={ msgText } /> : msgText;

        return (
            <div block="Notification" mods={ mods } ref={ this.notification } id={ id }>
                <button block="Notification" elem="Button" onClick={ this.hideNotification }> Close </button>
                <p block="Notification" elem="Text">
                     { message }
                </p>
                { this.renderDebug() }
            </div>
        );
    }
}

export default NotificationComponent;
