/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { prepareQuery } from 'Util/Query';
import { fetchQuery } from 'Util/Request/Query';
import { executeGet } from 'Util/Request/Request';
import getStore from 'Util/Store';

import PaymentMethodQuery from '../query/PaymentMethod.query';
import PurchaseQuery from '../query/Purchase.query';
import { roundPrice } from '../util/round';
import { waitForCallback } from '../util/wait';
import { getCartData } from './cart';
import { getCustomerData } from './customer';
import { getProductCategoriesData } from './product';

export const DL_VAL_EXISTING_CUSTOMER = 'Yes';
export const USER_EXISTING_CUSTOMER = 'Existing';
export const NEW_CUSTOMER = 'New';

/** @namespace Scandiweb/Gtm/Data/Purchase/findConfigurableOptionsById */
export const findConfigurableOptionsById = async (items, sku) => {
    const foundItem = items.find((item) => item.sku === sku);

    return foundItem
        ? {
            configurable_options: foundItem.configurable_options,
            sku: foundItem.product.sku,
            name: foundItem.product.name,
        }
        : null;
};

/** @namespace Scandiweb/Gtm/Data/Purchase/formatAttributesToString */
export const formatAttributesToString = async (attributes) => {
    if (!attributes || attributes.length === 0) {
        return '';
    }

    return attributes
        .map(({ option_label, value_label }) => `${option_label}-${value_label}`)
        .join('-');
};

/** @namespace Scandiweb/Gtm/Data/Purchase/getPurchaseProductsData */
export const getPurchaseProductsData = async (purchase, cartItems) => {
    const { products } = purchase;

    return Promise.all(products.map(async (product) => {
        const {
            category,
            sku,
            price,
            quantity,
            discount,
        } = product;

        const {
            configurable_options, sku: parentSku,
            name: parentName,
        } = await findConfigurableOptionsById(cartItems, sku);

        const productCategories = await getProductCategoriesData(category);
        const currencyCode = getStore().getState().ConfigReducer?.currencyData?.current_currency_code;
        const isEmptyObject = (obj) => Object.keys(obj).length === 0;
        const item_variant = (configurable_options && !isEmptyObject(configurable_options))
            ? await formatAttributesToString(configurable_options) : {};

        return {
            ...productCategories,
            item_id: parentSku,
            currency: currencyCode,
            item_name: parentName,
            price: roundPrice(price),
            ...(!isEmptyObject(item_variant) ? { item_variant } : {}),
            discount,
            quantity,
        };
    }));
};

/** @namespace Scandiweb/Gtm/Data/Purchase/getPurchaseShippingData */
export const getPurchaseShippingData = (purchase) => {
    const { shippingAddress, additional_data } = purchase;

    if (!additional_data || !shippingAddress) {
        return {};
    }

    const {
        city,
        postcode,
        region,
        region_id,
        street,
    } = shippingAddress;

    return {
        shipping_city: city,
        shipping_region: region,
        shipping_country_id: region_id,
        shipping_street: street.replace(/\r?\n|\r/g, ' '),
        shipping_postcode: postcode,
    };
};

/** @namespace Scandiweb/Gtm/Data/Purchase/getPurchaseCustomerData */
export const getPurchaseCustomerData = async (purchase) => {
    const { additional_data } = purchase;

    if (!additional_data) {
        return {};
    }
    const customerData = await getCustomerData();

    return {
        ...customerData,
        shipping_email: getStore().getState().CheckoutReducer?.email || '',
    };
};

/** @namespace Scandiweb/Gtm/Data/Purchase/getPurchaseEventData */
export const getPurchaseEventData = async (orderId, guestQuoteId, cartItems) => {
    // vvv Wait for currency code
    await waitForCallback(() => getStore().getState().ConfigReducer?.currencyData?.current_currency_code);

    const currencyCode = getStore().getState().ConfigReducer?.currencyData?.current_currency_code;

    const { customerId, userExistingCustomer } = await getCustomerData();
    const customer_status = userExistingCustomer === DL_VAL_EXISTING_CUSTOMER ? USER_EXISTING_CUSTOMER : NEW_CUSTOMER;

    const query = PurchaseQuery.getPurchaseField(orderId, guestQuoteId);
    const { purchase } = await fetchQuery(query);
    const {
        orderPaymentMethod,
        orderShippingMethod,
        revenue,
        tax,
        shipping,
        coupon,
        discount_amount,
    } = purchase;

    const total_value = roundPrice(revenue);

    return {
        ecommerce: {
            orderPaymentMethod,
            orderShippingMethod,
            discount_amount: Number(Math.abs(discount_amount)),
            customer_id: customerId,
            customer_status,
            currency: currencyCode,
            transaction_id: orderId,
            value: Number(total_value),
            tax: roundPrice(tax),
            ...(coupon ? { coupon } : {}),
            shipping: roundPrice(shipping),
            items: Array.from(await getPurchaseProductsData(purchase, cartItems)),
        },
    };
};

/** @namespace Scandiweb/Gtm/Data/Purchase/getPaymentData */
export const getPaymentData = async (code) => {
    const { cartTotals: { items } } = getStore().getState().CartReducer;
    const query = PaymentMethodQuery.getPaymentMethodField(code);
    const { getMethodTitle: { title } } = await executeGet(prepareQuery(query));

    const data = await getCartData(items);

    return {
        ecommerce: {
            payment_type: title,
            ...data,
        },
    };
};
