import { Field } from 'Util/Query';

/** @namespace Insign/ScandipwaCustomwebPayment/Query/SaferpayPayment/Query */
export class SaferpayPaymentQuery {
    getPaymentUrlQuery(cartId, returnUrl) {
        return new Field('getSaferpayPaymentUrl')
            .addArgument('input', 'SaferpayPaymentUrlInput!', { cart_id: cartId, return_url: returnUrl })
            .addFieldList([
                'success',
                'url',
                'authorisationMethod',
                'error_name',
                'errorCode'
            ]);
    }

    getRestoreCartMutation(cartId) {
        return new Field('restoreCart')
            .addArgument('cart_id', 'String!', cartId)
            .addField('cart_id');
    }
}

export default new SaferpayPaymentQuery();
