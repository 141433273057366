/* eslint-disable */
import { PureComponent } from 'react';

import './SearchIcon.style';

/** @namespace KaegiScandipwa/Component/SearchIcon/Component */
export class SearchIconComponent extends PureComponent {
    render() {
        return (
            <svg
              block="SearchIcon"
              width="24"
              height="24"
              viewBox="0 0 512 512"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path
                  d="M11.7,500.3c15.6,15.6,41,15.6,56.6,0L188,380.6c40.4,27.2,90.9,40.6,144.7,33.5
	                c91.5-12.2,166-87.3,177.6-178.9C527.5,99,413-15.5,276.8,1.7C185.2,13.3,110.2,87.8,97.9,179.3c-7.2,53.8,6.2,104.3,33.5,144.7
	                L11.7,443.7C-3.9,459.3-3.9,484.7,11.7,500.3z M304.9,336c-70.6,0-128-57.4-128-128s57.4-128,128-128s128,57.4,128,128
	                C432.9,278.6,375.5,336,304.9,336z"
                />
            </svg>
        );
    }
}

export default SearchIconComponent;
