/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

import { isSignedIn } from 'Util/Auth/Token';

/** @namespace Scandiweb/Gtm/Query/Purchase/Query */
export class PurchaseQuery {
    getPurchaseField(orderId, guestQuoteId) {
        const field = new Field('getPurchase')
            .setAlias('purchase')
            .addArgument('orderID', 'String!', orderId)
            .addFieldList(this.getPurchaseFields());

        if (!isSignedIn() && guestQuoteId) {
            field.addArgument('guestCartId', 'String', guestQuoteId);
        }

        return field;
    }

    getPurchaseFields() {
        return [
            new Field('orderPaymentMethod'),
            new Field('orderShippingMethod'),
            new Field('revenue'),
            new Field('tax'),
            new Field('shipping'),
            new Field('coupon'),
            new Field('discount_amount'),
            new Field('additional_data'),
            this.getProductField(),
            this.getShippingField(),
        ];
    }

    getProductField() {
        return new Field('purchaseProducts')
            .setAlias('products')
            .addFieldList(this.getProductFields());
    }

    getProductFields() {
        return [
            new Field('name'),
            new Field('id'),
            new Field('sku'),
            new Field('price'),
            new Field('quantity'),
            new Field('discount'),
            new Field('category'),
            new Field('dimensions'),
        ];
    }

    getShippingField() {
        return new Field('shippingAddress')
            .addFieldList(this.getShippingFields());
    }

    getShippingFields() {
        return [
            new Field('street'),
            new Field('city'),
            new Field('region_id'),
            new Field('region'),
            new Field('postcode'),
            new Field('email'),
        ];
    }
}
export default new PurchaseQuery();
