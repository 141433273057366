import {
    MyAccountQuery as SourceMyAccountQuery
} from 'SourceQuery/MyAccount.query';

/**
 * MyAccount Mutations
 * @class MyAccount
 * @namespace KaegiScandipwa/Query/MyAccount/Query */
export class MyAccountQuery extends SourceMyAccountQuery {
    _getAddressFields() {
        return [
            ...super._getAddressFields(),
            'company'
        ];
    }
}

export default new MyAccountQuery();
