/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { fireViewCartEvent } from '../../event/cart';

const afterComponentDidUpdate = async (args, callback, instance) => {
    callback(...args);

    const {
        isLoading,
        cartSubtotal,
        totals: {
            quote_currency_code: currencyCode,
            items,
        } = {},
    } = instance.props;
    const {
        isInitialLoad,
        isGTMViewCartEventDispatched,
    } = instance.state;

    // vvv Wait for cartItems to lead and initialLoad to finish and then send viewCart ONCE!
    if (
        !isLoading
        && !isInitialLoad
        && !isGTMViewCartEventDispatched
    ) {
        fireViewCartEvent(items, cartSubtotal, currencyCode);
        instance.setState({ isGTMViewCartEventDispatched: true });
    }
};

export default {
    'Route/CartPage/Container': {
        'member-function': {
            componentDidUpdate: afterComponentDidUpdate,
        },
    },
};
