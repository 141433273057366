/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import ChevronIcon from 'Component/ChevronIcon';
import {
    LEFT,
    RIGHT
} from 'Component/ChevronIcon/ChevronIcon.config';
import {
    Slider as SourceSlider
} from 'SourceComponent/Slider/Slider.component';

import './Slider.override.style';

/** @namespace KaegiScandipwa/Component/Slider/Component */
export class SliderComponent extends SourceSlider {
    goPrev() {
        const { activeImage, children } = this.props;

        if (activeImage > 0) {
            this.changeActiveImage(activeImage - 1);
        } else {
            this.changeActiveImage(children.length - 1);
        }
    }

    goNext() {
        const { activeImage, children } = this.props;
        const nextImage = activeImage + 1;

        if (nextImage < children.length) {
            this.changeActiveImage(nextImage);
        } else {
            this.changeActiveImage(0);
        }
    }

    renderArrows() {
        const { showArrows } = this.props;

        if (!showArrows) {
            return null;
        }

        return (
            <>
                <button
                  block="Slider"
                  elem="Arrow"
                  mods={ { isPrev: true, isDisabled: false } }
                  aria-label={ __('Previous') }
                  onClick={ this.goPrev }
                >
                    <ChevronIcon direction={ LEFT } />
                </button>
                <button
                  block="Slider"
                  elem="Arrow"
                  mods={ { isNext: true, isDisabled: false } }
                  aria-label={ __('Next') }
                  onClick={ this.goNext }
                >
                    <ChevronIcon direction={ RIGHT } />
                </button>
            </>
        );
    }
}

export default SliderComponent;
