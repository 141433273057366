import * as GtmEvents from '@scandiweb/gtm/src/util/events';

export const pushToDataLayerProxy = (args, callback, instance) => {
    const [data] = args;
    callback(...args);

    /* Can be used for local development
    if (!window.fbq) {
        window.fbq = (type, event, data) => {
            console.log('Meta Pixel not available');
            console.log(type, event, data);
        };
    }
    */

    if (window.fbq) {
        switch (data.event) {
        case GtmEvents.GTM_EVENT_KEY_ADD_TO_CART:
            window.fbq('track', 'AddToCart', {
                content_type: 'product',
                currency: data.ecommerce.currency,
                value: data.ecommerce.value,
                contents: data.ecommerce.items.map(
                    (item) => (
                        {
                            id: item.item_id,
                            quantity: item.quantity,
                            price: item.price
                        }
                    )
                )
            });
            break;

        case GtmEvents.GTM_EVENT_KEY_ADD_PAYMENT_INFO:
            window.fbq('track', 'AddPaymentInfo', {
                content_type: 'product',
                currency: data.ecommerce.currency,
                value: data.ecommerce.value,
                payment_type: data.ecommerce.payment_type,
                contents: data.ecommerce.items.map(
                    (item) => (
                        {
                            id: item.item_id,
                            quantity: item.quantity,
                            price: item.price
                        }
                    )
                )
            });
            break;

        case GtmEvents.GTM_EVENT_KEY_PURCHASE:
            const purchaseItemCount = data.ecommerce.items.reduce((count, curr) => count + curr.quantity, 0);
            window.fbq('track', 'Purchase', {
                content_type: 'product',
                currency: data.ecommerce.currency,
                value: data.ecommerce.value,
                num_items: purchaseItemCount,
                contents: data.ecommerce.items.map(
                    (item) => (
                        {
                            id: item.item_id,
                            quantity: item.quantity,
                            price: item.price
                        }
                    )
                )
            });
            break;

        case GtmEvents.GTM_EVENT_KEY_CHECKOUT:
            const itemCount = data.items.reduce((count, curr) => count + curr.quantity, 0);
            window.fbq('track', 'InitiateCheckout', {
                content_type: 'product',
                currency: data.currency,
                value: data.value,
                num_items: itemCount,
                contents: data.items.map(
                    (item) => (
                        {
                            id: item.item_id,
                            quantity: item.quantity,
                            price: item.price
                        }
                    )
                )
            });
            break;

        case GtmEvents.GTM_EVENT_KEY_USER_REGISTER:
            window.fbq('track', 'CompleteRegistration', { content_name: 'Kägi Shop', status: true });
            break;

        case GtmEvents.GTM_EVENT_KEY_IMPRESSIONS:
            window.fbq('track', 'ViewContent', {
                content_type: 'product',
                content_category: data.item_list_id,
                content_name: data.item_list_name,
                currency: data.currency,
                content_ids: data.items.map((item) => item.item_id)
            });
            break;

        case GtmEvents.GTM_EVENT_KEY_PRODUCT_DETAIL:
            window.fbq('track', 'ViewContent', {
                content_type: 'product',
                content_name: data.ecommerce.items[0].item_name,
                currency: data.ecommerce.currency,
                value: data.ecommerce.value,
                content_ids: data.ecommerce.items.map((item) => item.item_id)
            });
            break;

        case GtmEvents.GTM_EVENT_KEY_ADD_TO_WISHLIST:
            window.fbq('track', 'AddToWishlist', {
                content_type: 'product',
                currency: data.ecommerce.currency,
                value: data.ecommerce.value,
                contents: data.ecommerce.items.map(
                    (item) => (
                        {
                            id: item.item_id,
                            quantity: item.quantity
                        }
                    )
                )
            });
            break;

        default:
            break;
        }
    }
};

export default {
    'Scandiweb/Gtm/Util/Push/pushToDataLayer': {
        function: pushToDataLayerProxy
    }
};
