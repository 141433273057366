import {
    ImageContainer as SourceImageContainer
} from 'SourceComponent/Image/Image.container';
import PropTypes from 'prop-types';

/** @namespace KaegiScandipwa/Component/Image/Container */
export class ImageContainer extends SourceImageContainer {
    static propTypes = {
        ...SourceImageContainer.propTypes,
        onImageClick: PropTypes.func
    };

    static defaultProps = {
        ...SourceImageContainer.defaultProps,
        onImageClick: null
    };

    containerProps() {
        const { onImageClick } = this.props;
        return {
            ...super.containerProps(),
            onImageClick
        };
    }
}

export default ImageContainer;
