/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/Gtm/Query/PaymentMethod/Query */
export class PaymentMethodQuery {
    getPaymentMethodField(code) {
        return new Field('getMethodTitle')
            .setAlias('getMethodTitle')
            .addArgument('code', 'String!', code)
            .addFieldList(this.getPaymentMethodsFields());
    }

    getPaymentMethodsFields() {
        return [
            new Field('title'),
        ];
    }
}
export default new PaymentMethodQuery();
