import { Field } from 'Util/Query';

/** @namespace Insign/ScandipwaWalleePayment/Query/WalleePayment/Query */
export class WalleePaymentQuery {
    getQuery(cartId) {
        return new Field('getWalleePaymentConfig')
            .addArgument('cart_id', 'String!', cartId)
            .addField(this.getPaymentMethods())
            .addField(this.getCheckoutConfig());
    }

    getPaymentMethods() {
        return new Field('payment')
            .addFieldList([
                'isActive',
                'paymentMethodCode',
                'configurationId',
                'description',
                'showDescription',
                'imageUrl',
                'showImage'
            ]);
    }

    getCheckoutConfig() {
        return new Field('wallee')
            .addFieldList([
                'javascriptUrl',
                'lightboxUrl',
                'paymentPageUrl',
                'restoreCartUrl',
                'integrationMethod'
            ]);
    }

    getRestoreCartMutation(cartId) {
        return new Field('restoreCart')
            .addArgument('cart_id', 'String!', cartId)
            .addField('cart_id');
    }
}

export default new WalleePaymentQuery();
