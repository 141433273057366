/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request/Request';

import CategoryQuery from '../query/Category.query';
import { roundPrice } from '../util/round';

export const DL_VAL_CATEGORY_LIMIT = 5;

/** @namespace Scandiweb/Gtm/Data/Product/getProductDimensionsData */
export const getProductDimensionsData = async (product) => {
    const { variants = [], dimensions } = product;
    const { dimensions: childDimensions } = variants[0] || {};
    // eslint-disable-next-line fp/no-let
    let rawDimensions = childDimensions || dimensions;

    if (!rawDimensions) {
        return {};
    }
    rawDimensions = JSON.parse(rawDimensions);

    Object.keys(rawDimensions).forEach((key) => {
        rawDimensions[key] = rawDimensions[key].includes(',') ? rawDimensions[key].split(',')[0] : rawDimensions[key];
    });

    const enhancedVariantsArray = Object.keys(rawDimensions).filter((key) => key.includes('#'));
    const normalVariantsArray = Object.keys(rawDimensions).filter((key) => !key.includes('#'));
    const newRawDimensions = {};

    enhancedVariantsArray.forEach((enhancedVariant) => {
        if (newRawDimensions[enhancedVariant.split('#')[0]]) {
            // eslint-disable-next-line max-len
            newRawDimensions[enhancedVariant.split('#')[0]] = `${newRawDimensions[enhancedVariant.split('#')[0]]}-${rawDimensions[enhancedVariant]}`;
        } else {
            newRawDimensions[enhancedVariant.split('#')[0]] = rawDimensions[enhancedVariant];
        }
    });
    normalVariantsArray.forEach((normalVariant) => {
        newRawDimensions[normalVariant] = rawDimensions[normalVariant];
    });

    return newRawDimensions || rawDimensions || {};
};

/** @namespace Scandiweb/Gtm/Data/Product/getProductCategoriesData */
export const getProductCategoriesData = async (category) => {
    const categoriesNames = Array.isArray(category)
        ? category.filter((name) => name !== 'Default Category')
        : category.split('/').filter((name) => name !== 'Default Category');

    const slicedCategoriesNames = categoriesNames.slice(0, DL_VAL_CATEGORY_LIMIT);

    const categoriesObj = {};
    slicedCategoriesNames.forEach((category, index) => {
        if (index === 0) {
            categoriesObj.item_brand = category;

            return;
        }

        if (index === 1) {
            categoriesObj.item_category = category;

            return;
        }

        categoriesObj[`item_category${index}`] = category;
    });

    return categoriesObj;
};

/** @namespace Scandiweb/Gtm/Data/Product/getProductPriceData */
export const getProductPriceData = async (product) => {
    try {
        if (!product) {
            return 0;
        }
        const { type_id, price_range } = product;

        if (!price_range) {
            return -1;
            // ^^^ must be replaced, if price range is not present
        }

        if (type_id === 'grouped') {
            return 0;
        }

        const {
            price_range: {
                minimum_price: {
                    regular_price: {
                        value: price,
                        currency: currencyCode,
                    } = {},
                    discount: {
                        amount_off: discount = 0,
                    } = {},
                },
            } = {},
        } = product;

        return { price, discount, currencyCode };
    } catch (err) {
        return 0;
    }
};

/** @namespace Scandiweb/Gtm/Data/Product/getProductData */
export const getProductData = async (product) => {
    const {
        sku,
        name,
        quantity = 1,
    } = product;

    const { price, discount, currencyCode } = await getProductPriceData(product);
    const query = CategoryQuery.getCategoriesField(product.id);
    const { getCategories: { category: categories = [] } } = await executeGet(prepareQuery(query));

    const productCategories = await getProductCategoriesData(categories);
    const productDimensions = await getProductDimensionsData(product);

    return {
        item_id: sku,
        item_name: name,
        ...(price ? { price } : {}),
        discount: roundPrice(discount),
        ...(quantity ? { quantity } : {}),
        ...(currencyCode ? { currency: currencyCode } : {}),
        ...productCategories,
        ...productDimensions,
    };
};

/** @namespace Scandiweb/Gtm/Data/Product/getItemListId */
export const getItemListId = async (item_list_name) => ({
    item_list_id: item_list_name.replace(/[^\w\s]/g, '').replace(/\s+/g, '_').toLowerCase(),
});

/** @namespace Scandiweb/Gtm/Data/Product/getProductListEntryData */
export const getProductListEntryData = async (
    product,
    position,
    item_list_name
) => {
    const productData = await getProductData(product);
    const listId = await getItemListId(item_list_name);

    return {
        ...productData,
        index: position,
        item_list_name,
        ...listId,
    };
};

/** @namespace Scandiweb/Gtm/Data/Product/getProductDetailsData */
export const getProductDetailsData = async (product) => ({
    items: [await getProductData(product)],
});
