/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'Util/Store';

import { getCartData } from '../data/cart';
import { GTM_EVENT_KEY_ADD_SHIPPING_INFO } from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';
import { roundPrice } from '../util/round';

/** @namespace Scandiweb/Gtm/Event/General/fireAddShippingInfo */
export const fireAddShippingInfo = debounceCallback(
    async (shipping_tier, items, amount) => {
        const data = await getCartData(items);

        const {
            cartTotals: {
                prices: {
                    subtotal_including_tax: {
                        value: subtotal,
                    },
                    discount,
                },
            },
        } = getStore().getState().CartReducer;

        const discountValue = discount?.amount ? discount.amount.value : 0;

        // "discountValue" is a negative number
        const totalValue = Number(subtotal) + Number(amount) + Number(discountValue);

        pushToDataLayer({
            event: GTM_EVENT_KEY_ADD_SHIPPING_INFO,
            ecommerce: {
                shipping_tier,
                ...data,
                value: roundPrice(totalValue),
            },
        });
    }
);
