/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getCartId } from 'Util/Cart';
import getStore from 'Util/Store';

import { firePurchaseEvent } from '../../event/checkout';
import { fireAddPaymentInfo } from '../../event/payment';

const addFirePurchaseEvent = (args, callback) => {
    const cartItems = getStore().getState().CartReducer?.cartTotals?.items;
    const [orderId] = args;
    const cartId = getCartId();
    // ^^^ getting this here, as it will soon be reset
    firePurchaseEvent(orderId, cartId, cartItems);
    // vvv This must be called after guest quote id is obtained
    callback(...args);
};

const addFirePaymentInfoEvent = async (args, callback) => {
    const { paymentMethod: { code } } = args[0];

    fireAddPaymentInfo(code);

    callback(...args);
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            setDetailsStep: addFirePurchaseEvent,
            savePaymentMethodAndPlaceOrder: addFirePaymentInfoEvent,
        },
    },
};
