/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import WishlistQuery from 'Query/Wishlist.query';
import { showNotification } from 'Store/Notification/Notification.action';
import {
    clearWishlist,
    removeItemFromWishlist,
    updateAllProductsInWishlist,
    updateIsLoading,
} from 'Store/Wishlist/Wishlist.action';
import { isWishlistEnabled } from 'Store/Wishlist/Wishlist.dispatcher';
import { isSignedIn } from 'Util/Auth/Token';
import { fetchMutation } from 'Util/Request/Mutation';
import { fetchQuery } from 'Util/Request/Query';
import { getPriceRange } from 'Util/Wishlist';

import { getCustomerData } from '../../data/customer';
import { addToWishlistEventHandler } from '../../event/wishlist';

const addFireAddToWishlistEventHandler = async (args, callback) => {
    if (!isSignedIn()) {
        return;
    }
    const [, options] = args;

    await callback(...args);
    // ^^^ wait for 2 queries inside callback
    addToWishlistEventHandler(options.items);
};

const aroundClearWishlist = (args) => {
    const [dispatch] = args;

    if (!isSignedIn()) {
        return Promise.reject();
    }

    return fetchMutation(WishlistQuery.getClearWishlist())
        .then(
            () => {
                getCustomerData()
                    .then(
                        ({ customerId }) => dispatch({
                            ...clearWishlist(),
                            customerId,
                        })
                    )
                    .catch(
                        () => dispatch({ ...clearWishlist(), customerId: null })
                    );
            }
        )
        .catch(
            () => dispatch(showNotification('error', __('Error clearing wish list!')))
        );
};

const aroundUpdateInitialWishlistData = async (args, _callback, instance) => {
    const [dispatch] = args;
    const { customerId } = await getCustomerData();

    if (isSignedIn() && isWishlistEnabled()) {
        instance._syncWishlistWithBE(dispatch);
    } else {
        dispatch(updateAllProductsInWishlist({}, customerId));
    }
};

const aroundSyncWishlistWithBE = (args) => {
    const [dispatch] = args;

    // Need to get current wishlist from BE, update wishlist
    return fetchQuery(WishlistQuery.getWishlistQuery()).then(
        (data) => {
            if (data && data.wishlist) {
                const { wishlist } = data;
                const productsToAdd = wishlist.items.reduce((prev, wishlistItem) => {
                    const {
                        id,
                        sku,
                        product,
                        description,
                        price,
                        price_without_tax,
                        buy_request,
                        options,
                        qty: quantity,
                    } = wishlistItem;

                    const priceRange = getPriceRange(product, price, price_without_tax);

                    return {
                        ...prev,
                        [id]: {
                            ...product,
                            ...priceRange,
                            quantity,
                            wishlist: {
                                id,
                                sku,
                                quantity,
                                description,
                                buy_request,
                                options,
                            },
                        },
                    };
                }, {});

                getCustomerData()
                    .then(
                        ({ customerId }) => {
                            dispatch({
                                ...updateAllProductsInWishlist(productsToAdd),
                                customerId,
                            });
                        }
                    )
                    .catch(
                        () => dispatch({ ...updateAllProductsInWishlist(productsToAdd), customerId: null })
                    );
            } else {
                dispatch(updateIsLoading(false));
            }
        },
        /** @namespace Store/Wishlist/Dispatcher/WishlistDispatcher/_syncWishlistWithBE/fetchQuery/then/catch */
        () => {
            dispatch(updateIsLoading(false));
        }
    );
};

const aroundRemoveItemFromWishlist = async (args) => {
    const [
        dispatch,
        { item_id, noMessages },
    ] = args;

    if (!item_id || !isSignedIn()) {
        return Promise.reject();
    }

    dispatch(updateIsLoading(true));

    try {
        await fetchMutation(WishlistQuery.getRemoveProductFromWishlistMutation(item_id));
    } catch (e) {
        if (!noMessages) {
            dispatch(showNotification('error', __('Error updating wish list!')));
        }

        return Promise.reject();
    }

    const { customerId } = await getCustomerData();

    dispatch({ ...removeItemFromWishlist(item_id), customerId });

    if (!noMessages) {
        dispatch(showNotification('success', __('Product has been removed from your Wish List!')));
    }

    return Promise.resolve();
};

export default {
    'Store/Wishlist/Dispatcher': {
        'member-function': {
            addItemToWishlist: addFireAddToWishlistEventHandler,
            clearWishlist: aroundClearWishlist,
            updateInitialWishlistData: aroundUpdateInitialWishlistData,
            _syncWishlistWithBE: aroundSyncWishlistWithBE,
            removeItemFromWishlist: aroundRemoveItemFromWishlist,
        },
    },
};
