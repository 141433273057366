/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'Util/Store';

import { getCartItemsData } from './cart';

export const DL_VAL_CHECKOUT_SHIPPING_STEP = 1;
export const DL_VAL_CHECKOUT_BILLING_STEP = 2;
export const CHECKOUT_STEP_MAP = {
    [DL_VAL_CHECKOUT_SHIPPING_STEP]: 'Shipping',
    [DL_VAL_CHECKOUT_BILLING_STEP]: 'Billing',
};

/** @namespace Scandiweb/Gtm/Data/Checkout/getCheckoutEventData */
export const getCheckoutEventData = async () => {
    const {
        cartTotals: {
            prices: {
                grand_total: {
                    value: totalValue,
                    currency,
                },
            },
        },
    } = getStore().getState().CartReducer;

    return {
        currency,
        value: Number(totalValue),
        items: await getCartItemsData(),
    };
};

/** @namespace Scandiweb/Gtm/Data/Checkout/getCheckoutOptionEventData */
export const getCheckoutOptionEventData = async (step, option) => ({
    ecommerce: {
        checkout_option: {
            actionField: {
                option,
                step,
                action: CHECKOUT_STEP_MAP[step],
            },
        },
    },
});
