import { CART_TAB } from 'Component/NavigationTabs/NavigationTabs.config';
import CheckoutQuery from 'Query/Checkout.query';
import {
    DETAILS_STEP,
    PAYMENT_TOTALS
} from 'Route/Checkout/Checkout.config';
import { updateEmailAvailable } from 'Store/Checkout/Checkout.action';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteCartId, getCartId } from 'Util/Cart';
import { fetchMutation, fetchQuery } from 'Util/Request';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import { appendWithStoreCode } from 'Util/Url';

import { RESTORE_CART_STEP, SAFERPAY_PAYMENT_CODE, SAFERPAY_PAYMENT_REGEX } from '../config/Checkout.config';
import {
    paymentFailureHandler,
    paymentSuccessHandler,
    processFailureHandler,
    processRestoreCartHandler
} from '../helper/Checkout.helper';
import SaferpayPaymentQuery from '../query/SaferpayPayment.query';

const mapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        setIsEmailAvailable: (isEmailAvailable) => dispatch(updateEmailAvailable(isEmailAvailable))
    };
};

const mapStateToProps = (args, callback, instance) => {
    const [state] = args;

    return {
        ...callback(...args),
        gtmEvents: state.ConfigReducer.gtm
    };
};

const savePaymentMethodAndPlaceOrder = async (args, callback, instance) => {
    const [paymentInformation] = args;
    const { paymentMethod: { code, additional_data, purchase_order_number } } = paymentInformation;
    const guest_cart_id = getCartId();

    // check if it is a customweb checkout payment method
    if (!code.match(SAFERPAY_PAYMENT_REGEX)) {
        BrowserDatabase.setItem('default', 'payment_provider', ONE_MONTH_IN_SECONDS);
        return await callback(...args);
    }

    // if yes, do this custom logic
    try {
        // Just like the original function, we set the payment method
        await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
            cart_id: guest_cart_id,
            payment_method: {
                code,
                [code]: additional_data,
                purchase_order_number
            }
        }));

        if (instance.props.gtmEvents?.enabled) {
            const { fireAddPaymentInfo } = await import('@scandiweb/gtm/src/event/payment');
            fireAddPaymentInfo(code);
        }

        // and place the order
        const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(guest_cart_id));
        const { placeOrder: { order: { order_id } } } = orderData;

        const {
            getSaferpayPaymentUrl: {
                url,
                success
            }
        } = await fetchQuery(
            // eslint-disable-next-line max-len
            SaferpayPaymentQuery.getPaymentUrlQuery(guest_cart_id, `${location.origin}/paymentservice/processpayment/callback`)
        );

        if (success === '1') {
            const {
                billingAddress,
                shippingAddress,
                email
            } = instance.state;
            const {
                isEmailAvailable,
                totals
            } = instance.props;

            // set some data to local storage to show on success page
            BrowserDatabase.setItem(SAFERPAY_PAYMENT_CODE, 'payment_provider', ONE_MONTH_IN_SECONDS);
            BrowserDatabase.setItem({
                order_id,
                guest_cart_id,
                billingAddress,
                shippingAddress,
                isEmailAvailable,
                email,
                totals_items: totals.items,
                gtmEnabled: !!instance.props.gtmEvents?.enabled
            }, 'saferpay_payment_order_data', ONE_MONTH_IN_SECONDS);

            // const cwAuthData = await fetchMutation(CwPaymentQuery.getAuthorizeMutation(order_id));
            // const { cwAuthorize: { redirectionUrl } } = cwAuthData;
            // // window.history.pushState({}, document.title, appendWithStoreCode('/saferpaycw/checkout/restoreCart/'));
            window.history.pushState({}, '', appendWithStoreCode('/checkout/restoreCart'));
            window.location.href = url;
        }
    } catch (e) {
        instance._handleError(e);
    }
};

const __construct = (args, callback, instance) => {
    const [props] = args;
    const {
        toggleBreadcrumbs
    } = props;

    const payment_provider = BrowserDatabase.getItem('payment_provider');

    // check if we should show success page for postfinance checkout
    if (payment_provider === SAFERPAY_PAYMENT_CODE) {
        toggleBreadcrumbs(false);

        if (location.pathname.indexOf('checkout/onepage/success') > -1
            || location.pathname.indexOf('checkout/success') > -1) {
            const order_data = BrowserDatabase.getItem('saferpay_payment_order_data');
            if (order_data.gtmEnabled) {
                import('@scandiweb/gtm/src/event/checkout').then(({ firePurchaseEvent }) => {
                    const { order_id, guest_cart_id, totals_items } = order_data;
                    firePurchaseEvent(order_id, guest_cart_id, totals_items);
                });
            }
            paymentSuccessHandler(args, callback, instance);
        } else if (location.pathname.indexOf('checkout/restoreCart') > -1) {
            processRestoreCartHandler(args, callback, instance);
        } else {
            callback.call(instance, ...args);
        }
    } else {
        callback.call(instance, ...args);
    }
};

const componentDidMount = (args, callback, instance) => {
    const payment_provider = BrowserDatabase.getItem('payment_provider');
    if ((instance.state.checkoutStep !== DETAILS_STEP
        && instance.state.checkoutStep !== RESTORE_CART_STEP)
        || payment_provider !== SAFERPAY_PAYMENT_CODE) {
        callback(...args);
    }
    if (payment_provider === SAFERPAY_PAYMENT_CODE && instance.state.checkoutStep === DETAILS_STEP) {
        const {
            setIsEmailAvailable,
            resetCart,
            resetGuestCart,
            setNavigationState
        } = instance.props;

        if (instance.state.hasOwnProperty('isEmailAvailable')) {
            setIsEmailAvailable(instance.state.isEmailAvailable);
        }

        deleteCartId();
        BrowserDatabase.deleteItem(PAYMENT_TOTALS);

        if (isSignedIn()) {
            resetCart();
        } else {
            resetGuestCart();
        }

        setNavigationState({
            name: CART_TAB
        });
    }
    BrowserDatabase.setItem('default', 'payment_provider', ONE_MONTH_IN_SECONDS);
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            savePaymentMethodAndPlaceOrder,
            __construct,
            componentDidMount
        }
    },
    'Route/Checkout/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Route/Checkout/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
