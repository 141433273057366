/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    isUsingClientHints,
    isMobileClientHints,
    isMobile as sourceIsMobile
} from 'SourceUtil/Mobile/isMobile';

export {
    isUsingClientHints,
    isMobileClientHints
};

export const isMobile = {
    ...sourceIsMobile,
    any: () => window.matchMedia('(max-width: 1180px)').matches && window.matchMedia('screen').matches
};

export default isMobile;
