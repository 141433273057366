import {
    lazy
} from 'react';

import {
    PRODUCT_EXPLORER,
    BEFORE_ITEMS_TYPE,
    DELIVERY_MODAL
} from './Router.config';

import {
    Router as SourceRouter
} from 'SourceComponent/Router/Router.component';

import './Router.override.style';

/* eslint-disable-next-line */
export const ProductExplorer = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Component/ProductExplorer'));
/* eslint-disable-next-line */
export const DeliveryModal = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Component/DeliveryModal'));

/** @namespace KaegiScandipwa/Component/Router/Component */
export class RouterComponent extends SourceRouter {
    /* eslint-disable-next-line  @scandipwa/scandipwa-guidelines/no-jsx-variables */
    [BEFORE_ITEMS_TYPE] = [
        ...this.BEFORE_ITEMS_TYPE,
        {
            component: <DeliveryModal />,
            position: 28,
            name: DELIVERY_MODAL
        },
        {
            component: <ProductExplorer />,
            position: 31,
            name: PRODUCT_EXPLORER
        }
    ];
}

export default RouterComponent;
