/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/Gtm/Query/Config/Query */
export class ConfigQuery {
    _getEventFields() {
        return [
            new Field('gtm_general_init'),
            new Field('gtm_impressions_batch_limit'),
            new Field('gtm_impressions'),
            // * Combined these 3 vvv to this ^^^
            // 'gtm_impressions_plp',
            // 'gtm_impressions_search',
            // 'gtm_impressions_home,
            new Field('gtm_site_search'),
            new Field('gtm_site_search_started'),
            new Field('gtm_product_click'),
            new Field('gtm_product_detail'),
            new Field('gtm_product_add_to_cart'),
            new Field('gtm_product_remove_from_cart'),
            new Field('gtm_view_cart'),
            new Field('gtm_purchase'),
            new Field('gtm_checkout'),
            new Field('gtm_checkout_option'),
            new Field('gtm_user_login'),
            new Field('gtm_user_login_attempt'),
            new Field('gtm_user_register'),
            new Field('gtm_user_register_attempt'),
            new Field('gtm_user_logout'),
            new Field('gtm_not_found'),
            new Field('gtm_page_reload'),
            new Field('gtm_error_message'),
            new Field('gtm_sort'),
            new Field('gtm_add_to_wishlist'),
            new Field('gtm_remove_from_wishlist'),
            new Field('gtm_remove_all_items_from_wishlist'),
            new Field('gtm_filter_applied'),
            new Field('gtm_filter_removed'),
            new Field('gtm_all_filters_removed'),
            new Field('gtm_view_promotion'),
            new Field('gtm_select_promotion'),
            new Field('gtm_add_shipping_info'),
            new Field('gtm_add_payment_info'),
            new Field('gtm_generate_customer'),
        ];
    }

    _getEventField() {
        return new Field('events')
            .addFieldList(this._getEventFields());
    }

    _getConfigFields() {
        return [
            new Field('enabled'),
            new Field('gtm_id'),
            new Field('googleOptimizeEnabled'),
            new Field('googleOptimizeId'),
            new Field('googleOptimizeAsyncEnabled'),
            new Field('antiFlickerEnabled'),
            this._getEventField(),
        ];
    }

    getGtmField() {
        return new Field('getGtm')
            .setAlias('gtm')
            .addFieldList(this._getConfigFields());
    }
}

export default new ConfigQuery();
