/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/Gtm/Query/Category/Query */
export class CategoryQuery {
    getCategoriesField(productID) {
        const field = new Field('getCategories')
            .setAlias('getCategories')
            .addArgument('productID', 'String!', productID)
            .addFieldList(this.getCategoryField());

        return field;
    }

    getCategoryField() {
        return [
            new Field('category'),
        ];
    }
}
export default new CategoryQuery();
