/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { DL_VAL_CHECKOUT_BILLING_STEP } from '../../data/checkout';
import { fireCheckoutOptionEvent } from '../../event/checkout';

const addFireCheckoutOptionEventForBilling = (args, callback, instance) => {
    callback(...args);

    const { paymentMethod } = instance.state;

    fireCheckoutOptionEvent(
        DL_VAL_CHECKOUT_BILLING_STEP,
        paymentMethod
    );
};

export default {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            onBillingSuccess: addFireCheckoutOptionEventForBilling,
        },
    },
};
