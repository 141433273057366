/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'Util/Store';

import { DL_VAL_CHECKOUT_SHIPPING_STEP } from '../../data/checkout';
import { fireCheckoutEvent } from '../../event/checkout';
import { fireAddShippingInfo } from '../../event/shipping';

const getSelectedShippingMethod = async (shippingMethods) => {
    const selectedMethod = shippingMethods.find((method) => method.value === true);

    return selectedMethod ? selectedMethod.name : null;
};

const addFireCheckoutOptionEventForShipping = async (args, callback, instance) => {
    await callback(...args);

    const methodTag = await getSelectedShippingMethod(args[1]);
    const methodName = methodTag.split('-');
    const methodInfo = instance.props.shippingMethods.find((method) => methodName[1] === method.carrier_title);
    const { carrier_title, method_title, amount } = methodInfo;

    const shipping_tier = `${carrier_title } - ${ method_title}`;
    const { cartTotals: { items } } = getStore().getState().CartReducer;
    fireAddShippingInfo(shipping_tier, items, amount);
};

const addFireCheckoutEventForShipping = (args, callback) => {
    callback(...args);
    fireCheckoutEvent(DL_VAL_CHECKOUT_SHIPPING_STEP);
};

export default {
    'Component/CheckoutShipping/Container': {
        'member-function': {
            onShippingSuccess: addFireCheckoutOptionEventForShipping,
            componentDidMount: addFireCheckoutEventForShipping,
        },
    },
};
