import {
    DETAILS_STEP,
    PAYMENT_TOTALS,
    SHIPPING_STEP
} from 'Route/Checkout/Checkout.config';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getCartId, setCartId } from 'Util/Cart';
import { fetchMutation } from 'Util/Request';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import { RESTORE_CART_STEP } from '../config/Checkout.config';
import SaferpayPaymentQuery from '../query/SaferpayPayment.query';

/** @namespace Insign/ScandipwaCustomwebPayment/Helper/Checkout/Helper/paymentSuccessHandler */
export const paymentSuccessHandler = (args, originalConstructor, instance) => {
    const order_data = BrowserDatabase.getItem('saferpay_payment_order_data');
    // remove order info from local storage
    BrowserDatabase.setItem({}, 'saferpay_payment_order_data', ONE_MONTH_IN_SECONDS);
    const {
        order_id,
        billingAddress,
        isEmailAvailable,
        email
    } = order_data;

    // do similar things like in setDetailsStep function
    instance.state = {
        isLoading: false,
        isDeliveryOptionsLoading: false,
        requestsSent: 0,
        paymentMethods: [],
        shippingMethods: [],
        shippingAddress: {},
        selectedShippingMethod: '',
        isGuestEmailSaved: true,
        isCreateUser: false,
        estimateAddress: {},
        isPickInStoreMethodSelected: false,
        checkoutStep: DETAILS_STEP,
        paymentTotals: BrowserDatabase.getItem(PAYMENT_TOTALS) || {},
        email,
        billingAddress,
        orderID: order_id,
        isEmailAvailable
    };
};

/** @namespace Insign/ScandipwaCustomwebPayment/Helper/Checkout/Helper/restoreCart */
export const restoreCart = async (props, order_id) => {
    const formDataJson = {
        orderId: order_id,
        action: 'error',
        method: 'PaymentPage',
        isAjax: true
    };
    const response = await fetch(`${location.origin}/paymentservice/checkout/SessionHandler`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
            "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            "X-Requested-With": "XMLHttpRequest"
        },
        body: Object.keys(formDataJson).map(key => `${key}=${formDataJson[key]}`).join('&')
    });
    const responseData = await response.json();

    setCartId(responseData.quote_id);
    return;
};

/** @namespace Insign/ScandipwaCustomwebPayment/Helper/Checkout/Helper/defaultFailureHandler */
export const defaultFailureHandler = async (args, originalConstructor, instance) => {
    const [props] = args;
    const { showErrorNotification } = props;

    const order_data = BrowserDatabase.getItem('saferpay_payment_order_data');
    // remove order info from local storage
    BrowserDatabase.setItem({}, 'saferpay_payment_order_data', ONE_MONTH_IN_SECONDS);
    const {
        order_id,
        billingAddress,
        shippingAddress,
        email
    } = order_data;

    showErrorNotification(__('Payment process was canceled!'));
    instance.state = {
        isLoading: false,
        isDeliveryOptionsLoading: false,
        requestsSent: 0,
        paymentMethods: [],
        shippingMethods: [],
        shippingAddress,
        selectedShippingMethod: '',
        isGuestEmailSaved: false,
        isCreateUser: false,
        estimateAddress: {},
        isPickInStoreMethodSelected: false,
        estimateAddress: {},
        checkoutStep: RESTORE_CART_STEP,
        paymentTotals: BrowserDatabase.getItem(PAYMENT_TOTALS) || {},
        email,
        billingAddress,
        orderID: order_id
    };
    await restoreCart(props, order_id);
    await props.resetCart();

    BrowserDatabase.deleteItem(PAYMENT_TOTALS);

    // eslint-disable-next-line react/no-did-update-set-state
    instance.setState({
        checkoutStep: SHIPPING_STEP
    });
};

/** @namespace Insign/ScandipwaCustomwebPayment/Helper/Checkout/Helper/paymentFailureHandler */
export const paymentFailureHandler = async (args, originalConstructor, instance) => {
    defaultFailureHandler(args, originalConstructor, instance);
};

/** @namespace Insign/ScandipwaCustomwebPayment/Helper/Checkout/Helper/processFailureHandler */
export const processFailureHandler = async (args, originalConstructor, instance) => {
    defaultFailureHandler(args, originalConstructor, instance);
};

/** @namespace Insign/ScandipwaCustomwebPayment/Helper/Checkout/Helper/processRestoreCartHandler */
export const processRestoreCartHandler = async (args, originalConstructor, instance) => {
    defaultFailureHandler(args, originalConstructor, instance);
};
