import {
    Image as SourceImage
} from 'SourceComponent/Image/Image.component';
import PropTypes from 'prop-types';

/** @namespace KaegiScandipwa/Component/Image/Component */
export class ImageComponent extends SourceImage {
    static propTypes = {
        ...SourceImage.propTypes,
        onImageClick: PropTypes.func
    };

    static defaultProps = {
        ...SourceImage.defaultProps,
        onImageClick: null
    };

    render() {
        const {
            ratio,
            mix,
            isPlaceholder,
            wrapperSize,
            src,
            imageRef,
            className,
            isPlain,
            onImageClick
        } = this.props;

        const { imageStatus } = this.state;

        // render image as is: without additional container and additional styles
        if (isPlain) {
            return this.renderImage();
        }

        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
              block="Image"
              ref={ imageRef }
              mods={ {
                  ratio,
                  imageStatus: imageStatus.toLowerCase(),
                  isPlaceholder,
                  hasSrc: !!src
              } }
              mix={ mix }
              onClick={ onImageClick }
              style={ wrapperSize }
              // eslint-disable-next-line react/forbid-dom-props
              className={ className }
            >
                { this.renderImage() }
                { this.renderLoader() }
            </div>
        );
    }
}

export default ImageComponent;
